<template>
    <div class="wrapper">
        <notifications></notifications>
        <side-bar>
            <template slot="links">

                <!-- DASHBOARD -->
                <sidebar-item :link="{ name: 'Dashboard', icon: 'fa fa-industry' }"
                    v-if="authUserPermission['my-task-view'] || authUserPermission['my-notifications-view'] || authUserPermission['mcr-status-view'] || authUserPermission['material-status-view']">
                    <!-- <sidebar-item :link="{name: 'My Work', path: '/dashboard/my-work'}"></sidebar-item> -->
                    <sidebar-item :link="{ name: 'My Task', path: '/dashboard/my-task' }"
                        v-if="authUserPermission['my-task-view']"></sidebar-item>
                    <sidebar-item :link="{ name: 'My Notification', path: '/dashboard/my-notification' }"
                        v-if="authUserPermission['my-notifications-view']"></sidebar-item>
                    <sidebar-item :link="{ name: 'MCR Status', path: '/dashboard/mcr-status' }"
                        v-if="authUserPermission['mcr-status-view']"></sidebar-item>
                    <sidebar-item :link="{ name: 'Material Status', path: '/dashboard/material-status' }"
                        v-if="authUserPermission['material-status-view']"></sidebar-item>
                </sidebar-item>

                <!-- SEARCH -->

                <sidebar-item :link="{ name: 'Search Material', icon: 'fa fa-search' }"
                    v-if="authUserPermission['end-user-search-material-view'] || authUserPermission['end-user-search-characteristics-view'] || authUserPermission['end-user-simple-search-material-view']">
                    <sidebar-item :link="{ name: 'Advance Search Material', path: '/general_layout/search-material' }"
                        v-if="authUserPermission['end-user-search-material-view']"></sidebar-item>
                    <sidebar-item
                        :link="{ name: 'Search By Characteristic', path: '/general_layout/search-characteristic' }"
                        v-if="authUserPermission['end-user-search-characteristics-view']"></sidebar-item>
                    <sidebar-item
                        :link="{ name: 'Simple Search Material', path: '/general_layout/simple-search-material' }"
                        v-if="authUserPermission['end-user-simple-search-material-view']"></sidebar-item>
                </sidebar-item>
                <!-- MESSAGE -->
                <sidebar-item :link="{ name: 'Message', icon: 'fa fa-envelope', path: '/message' }"
                    v-if="authUserPermission['message-view']"></sidebar-item>

                <!-- MATERIAL CATALOG REQUEST -->
                <sidebar-item :link="{ name: 'Material Catalog Request', icon: 'fa fa-ticket-alt' }"
                    v-if="authUserPermission['mcr-draft-list-view'] || authUserPermission['mcr-material-request-view']">
                    <sidebar-item :link="{ name: 'Draft List', path: '/material/draft-list' }"
                        v-if="authUserPermission['mcr-draft-list-view']"></sidebar-item>
                    <sidebar-item :link="{ name: 'Material Request', path: '/material/material-request' }"
                        v-if="authUserPermission['mcr-material-request-view']"></sidebar-item>
                </sidebar-item>

                <!-- STOCK CALCULATION -->
                <sidebar-item :link="{ name: 'Stock Calculation', icon: 'fa fa-calculator' }"
                    v-if="authUserPermission['stock-calc-fast-by-demand'] || authUserPermission['stock-calc-fast-by-demand-multiple'] || authUserPermission['stock-calc-fast-by-benefit-cost-ratio']">
                    <!-- <sidebar-item :link="{name: 'Stock Calculation', icon: 'fa fa-calculator'}" v-if="authUserPermission['stock-calc-fast-by-demand'] || authUserPermission['stock-calc-fast-by-manual'] || authUserPermission['stock-calc-fast-by-demand-multiple'] || authUserPermission['stock-calc-recommend'] || authUserPermission['stock-calc-slow-by-demand'] || authUserPermission['stock-calc-slow-by-manual'] || authUserPermission['stock-calc-slow-by-demand']"> -->
                    <!-- <sidebar-item :link="{name: 'Model Recommendation', path:'/stock-calculation/model-recommendation'}" v-if="authUserPermission['stock-calc-recommend']"></sidebar-item> -->
                    <!-- <sidebar-item :link="{name: 'by History Demand', path: '/stock-calculation/sm-history-demand'}"></sidebar-item> -->
                    <!-- <sidebar-item :link="{name: 'Calculator Min-Max'}" v-if="authUserPermission['stock-calc-fast-by-demand'] || authUserPermission['stock-calc-fast-by-manual'] || authUserPermission['stock-calc-fast-by-demand-multiple']">
                        <sidebar-item :link="{name: 'Single Material Code', path: '/stock-calculation/fm-single-material-code'}" v-if="authUserPermission['stock-calc-fast-by-demand']"></sidebar-item>
                        <sidebar-item :link="{name: 'Multiple Material Code', path: '/stock-calculation/stock-calc-multiple'}"  v-if="authUserPermission['stock-calc-fast-by-demand-multiple']"></sidebar-item>
                        <sidebar-item :link="{name: 'Manual Input', path: '/stock-calculation/fm-manual'}" v-if="authUserPermission['stock-calc-fast-by-manual']"></sidebar-item>
                    </sidebar-item> -->
                    <!-- <sidebar-item :link="{name: 'Calculator Service Level'}" v-if="authUserPermission['stock-calc-slow-by-manual'] || authUserPermission['stock-calc-slow-by-demand']">
                        <sidebar-item :link="{name: 'History Demand', path: '/stock-calculation/sm-history-demand'}" v-if="authUserPermission['stock-calc-slow-by-demand']"></sidebar-item>
                        <sidebar-item :link="{name: 'Manual Input', path: '/stock-calculation/sm-manual'}" v-if="authUserPermission['stock-calc-slow-by-manual']"></sidebar-item>
                    </sidebar-item> -->
                    <sidebar-item
                        :link="{ name: 'Single Material Code', path: '/stock-calculation/fm-single-material-code' }"
                        v-if="authUserPermission['stock-calc-fast-by-demand']"></sidebar-item>
                    <sidebar-item
                        :link="{ name: 'Multiple Material Code', path: '/stock-calculation/stock-calc-multiple' }"
                        v-if="authUserPermission['stock-calc-fast-by-demand-multiple']"></sidebar-item>
                    <sidebar-item
                        :link="{ name: 'Benefit Cost Ratio', path: '/stock-calculation/stock-calc-benefit-cost-ratio' }"
                        v-if="authUserPermission['stock-calc-fast-by-benefit-cost-ratio']"></sidebar-item>

                </sidebar-item>

                <!-- DICTIONARY -->
                <sidebar-item :link="{ name: 'Dictionary', icon: 'fa fa-book' }"
                    v-if="authUserPermission['item-name-view'] || authUserPermission['characteristic-master-view'] || authUserPermission['characteristic-item-name-view'] || authUserPermission['abbreviation-view'] || authUserPermission['nato-group-class-view'] || authUserPermission['smartcat-attachment-view'] || authUserPermission['scope-of-supply-view'] || authUserPermission['equipment-code-view'] || authUserPermission['drawing-master-view']">
                    <sidebar-item :link="{ name: 'Standard Item Name', path: '/dictionary/standard-item-name' }"
                        v-if="authUserPermission['item-name-view']"></sidebar-item>
                    <sidebar-item :link="{ name: 'Characteristic Master' }"
                        v-if="authUserPermission['characteristic-master-view'] || authUserPermission['characteristic-item-name-view']">
                        <sidebar-item :link="{ name: 'Characteristics', path: '/dictionary/characteristic-master' }"
                            v-if="authUserPermission['characteristic-master-view']"></sidebar-item>
                        <sidebar-item
                            :link="{ name: 'Characteristic Item Name', path: '/dictionary/characteristic-item-name' }"
                            v-if="authUserPermission['characteristic-item-name-view']"></sidebar-item>
                        <sidebar-item
                            :link="{ name: 'Characteristic Value', path: '/dictionary/characteristic-value' }"></sidebar-item>
                    </sidebar-item>
                    <sidebar-item :link="{ name: 'Abbreviation', path: '/dictionary/abbreviation' }"
                        v-if="authUserPermission['abbreviation-view']"></sidebar-item>
                    <sidebar-item :link="{ name: 'Nato Group Class', path: '/dictionary/nato-group-class' }"
                        v-if="authUserPermission['nato-group-class-view']"></sidebar-item>
                    <sidebar-item :link="{ name: 'SmartCat Attachment', path: '/dictionary/smartcat-attachment' }"
                        v-if="authUserPermission['smartcat-attachment-view']"></sidebar-item>
                    <sidebar-item :link="{ name: 'Scope of Supply', path: '/dictionary/scope-of-supply' }"
                        v-if="authUserPermission['scope-of-supply-view']"></sidebar-item>
                    <sidebar-item :link="{ name: 'Equipment', path: '/dictionary/equipment' }"
                        v-if="authUserPermission['equipment-code-view']"></sidebar-item>
                    <sidebar-item :link="{ name: 'BOM', icon: 'fa fa-list-alt', path: '/dictionary/bom' }"
                        v-if="authUserPermission['bom-view']"></sidebar-item>
                    <sidebar-item :link="{ name: 'Drawing Master', path: '/dictionary/drawing-master' }"
                        v-if="authUserPermission['drawing-master-view']"></sidebar-item>
                </sidebar-item>

                <!-- MATERIAL DUPLICATION -->
                <sidebar-item
                    :link="{ name: 'Material Duplication', icon: 'fa fa-clone', path: '/material-duplication/display' }"
                    v-if="authUserPermission['duplicate-material-view']"></sidebar-item>

                <!-- GENERAL MASTER DATA -->
                <sidebar-item :link="{ name: 'General Master Data', icon: 'fa fa-database' }"
                    v-if="authUserPermission['manufacture-view'] || authUserPermission['material-type-view'] || authUserPermission['material-group-view'] || authUserPermission['uom-view'] || authUserPermission['company-code-view'] || authUserPermission['plant-view'] || authUserPermission['location-code-view'] || authUserPermission['valuation-class-view'] || authUserPermission['catalog-status-view'] || authUserPermission['catalog-type-view'] || authUserPermission['manref-type-view'] || authUserPermission['source-type-view'] || authUserPermission['sales-organization-view'] || authUserPermission['account-assignment-group-view'] || authUserPermission['profit-center-view'] || authUserPermission['price-control-view'] || authUserPermission['purchasing-group-view'] || authUserPermission['sproctype-view'] || authUserPermission['proc-type-view'] || authUserPermission['loading-group-view'] || authUserPermission['abc-indicator-view'] || authUserPermission['mrp-controller-view'] || authUserPermission['mrp-types-view'] || authUserPermission['lot-size-view'] || authUserPermission['item-cat-group-view'] || authUserPermission['tax-class-view'] || authUserPermission['division-view'] || authUserPermission['trans-group-view'] || authUserPermission['warehouse-view'] || authUserPermission['storage-section-view'] || authUserPermission['storage-type-view']">
                    <sidebar-item :link="{ name: 'Manufacturer', path: '/master/manufacturer' }"
                        v-if="authUserPermission['manufacture-view']"></sidebar-item>
                    <sidebar-item :link="{ name: 'Material Type', path: '/master/material-type' }"
                        v-if="authUserPermission['material-type-view']"></sidebar-item>
                    <sidebar-item :link="{ name: 'Material Group', path: '/master/material-group' }"
                        v-if="authUserPermission['material-group-view']"></sidebar-item>
                    <!-- <sidebar-item :link="{name: 'Service Category', path: '/master/service-category'}"></sidebar-item>
                    <sidebar-item :link="{name: 'Service Group', path: '/master/service-group'}"></sidebar-item> -->
                    <sidebar-item :link="{ name: 'Unit of Measure', path: '/master/unit-of-measure' }"
                        v-if="authUserPermission['uom-view']"></sidebar-item>
                    <!-- <sidebar-item :link="{name: 'Client', path: '/master/holding-code'}"></sidebar-item> -->
                    <sidebar-item :link="{ name: 'Company Code', path: '/master/company-code' }"
                        v-if="authUserPermission['company-code-view']"></sidebar-item>
                    <sidebar-item :link="{ name: 'Plant Code', path: '/master/plant-code' }"
                        v-if="authUserPermission['plant-view']"></sidebar-item>
                    <!-- <sidebar-item :link="{name: 'Plant Sp', path: '/master/plant-sp'}"></sidebar-item> -->
                    <sidebar-item :link="{ name: 'Storage Location', path: '/master/location-code' }"
                        v-if="authUserPermission['location-code-view']"></sidebar-item>
                    <sidebar-item :link="{ name: 'Valuation Class', path: '/master/valuation-class' }"
                        v-if="authUserPermission['valuation-class-view']"></sidebar-item>
                    <sidebar-item :link="{ name: 'Catalog Status', path: '/master/catalog-status' }"
                        v-if="authUserPermission['catalog-status-view']"></sidebar-item>
                    <sidebar-item :link="{ name: 'Catalog Type', path: '/master/catalog-type' }"
                        v-if="authUserPermission['catalog-type-view']"></sidebar-item>
                    <sidebar-item :link="{ name: 'Manref Type', path: '/master/manref-type' }"
                        v-if="authUserPermission['manref-type-view']"></sidebar-item>
                    <sidebar-item :link="{ name: 'Source Type', path: '/master/source-type' }"
                        v-if="authUserPermission['source-type-view']"></sidebar-item>
                    <sidebar-item :link="{ name: 'Sales Organization', path: '/master/sales-organization' }"
                        v-if="authUserPermission['sales-organization-view']"></sidebar-item>
                    <sidebar-item :link="{ name: 'Distribution Channel', path: '/master/distribution-channel' }"
                        v-if="authUserPermission['distribution-channel-view']"></sidebar-item>
                    <!-- <sidebar-item :link="{name: 'Warehouse', path: '/master/warehouse'}"></sidebar-item> -->
                    <sidebar-item :link="{ name: 'Account Assignment Group', path: '/master/account-assignment-group' }"
                        v-if="authUserPermission['account-assignment-group-view']"></sidebar-item>
                    <!-- <sidebar-item :link="{name: 'Product Hierarchy', path: '/master/product-hierarchy'}"></sidebar-item> -->
                    <sidebar-item :link="{ name: 'Profit Center', path: '/master/profit-center' }"
                        v-if="authUserPermission['profit-center-view']"></sidebar-item>
                    <sidebar-item :link="{ name: 'Price Control', path: '/master/price-control' }"
                        v-if="authUserPermission['price-control-view']"></sidebar-item>
                    <sidebar-item :link="{ name: 'Purchasing Group', path: '/master/purchasing-group' }"
                        v-if="authUserPermission['purchasing-group-view']"></sidebar-item>
                    <!-- <sidebar-item :link="{name: 'Account Category Refference', path: '/master/account-category-refference'}"></sidebar-item> -->
                    <sidebar-item :link="{ name: 'Special Procurement Type', path: '/master/special-procurement-type' }"
                        v-if="authUserPermission['sproctype-view']"></sidebar-item>
                    <sidebar-item :link="{ name: 'Procurement Type', path: '/master/procurement-type' }"
                        v-if="authUserPermission['proc-type-view']"></sidebar-item>
                    <sidebar-item :link="{ name: 'Loading Group', path: '/master/loading-group' }"
                        v-if="authUserPermission['loading-group-view']"></sidebar-item>
                    <sidebar-item :link="{ name: 'ABC Indicator', path: '/master/abc-indicator' }"
                        v-if="authUserPermission['abc-indicator-view']"></sidebar-item>
                    <sidebar-item :link="{ name: 'MRP Controller', path: '/master/mrp-controller' }"
                        v-if="authUserPermission['mrp-controller-view']"></sidebar-item>
                    <sidebar-item :link="{ name: 'MRP Types', path: '/master/mrp-types' }"
                        v-if="authUserPermission['mrp-types-view']"></sidebar-item>
                    <sidebar-item :link="{ name: 'Lot Size', path: '/master/lot-size' }"
                        v-if="authUserPermission['lot-size-view']"></sidebar-item>
                    <sidebar-item :link="{ name: 'Item Category Group', path: '/master/item-category-group' }"
                        v-if="authUserPermission['item-cat-group-view']"></sidebar-item>
                    <sidebar-item :link="{ name: 'Tax Classification', path: '/master/tax-classification' }"
                        v-if="authUserPermission['tax-class-view']"></sidebar-item>
                    <sidebar-item :link="{ name: 'Division', path: '/master/division' }"
                        v-if="authUserPermission['division-view']"></sidebar-item>
                    <sidebar-item :link="{ name: 'Transportation Group', path: '/master/transportation-group' }"
                        v-if="authUserPermission['trans-group-view']"></sidebar-item>
                    <sidebar-item :link="{ name: 'Warehouse', path: '/master/warehouse' }"
                        v-if="authUserPermission['warehouse-view']"></sidebar-item>
                    <sidebar-item :link="{ name: 'Storage Section', path: '/master/storage-section' }"
                        v-if="authUserPermission['storage-section-view']"></sidebar-item>
                    <sidebar-item :link="{ name: 'Storage Type', path: '/master/storage-type' }"
                        v-if="authUserPermission['storage-type-view']"></sidebar-item>
                </sidebar-item>

                <!-- SAP -->
                <sidebar-item :link="{ name: 'SAP', icon: 'fa fa-tasks', path: '/dashboard' }"
                    v-if="authUserPermission['sap-connection-view'] || authUserPermission['sap-sync-view'] || authUserPermission['sap-sync-task-view']">
                    <sidebar-item :link="{ name: 'Master Data Sync', path: '/sap/sap-sync' }"></sidebar-item>
                    <sidebar-item :link="{ name: 'Sap Sync Task', path: '/sap/sap-sync-task' }"
                        v-if="authUserPermission['sap-sync-task-view']"></sidebar-item>
                </sidebar-item>

                <!-- REPORT -->
                <sidebar-item :link="{ name: 'Report', icon: 'fa fa-file', path: '/dashboard' }"
                    v-if="authUserPermission['duplicate-view'] || authUserPermission['export-view'] || authUserPermission['mcr-process-view'] || authUserPermission['konsolidasi-report-view']">
                    <sidebar-item :link="{ name: 'Check Duplicate', path: '/report/check-duplicate' }"
                        v-if="authUserPermission['duplicate-view']"></sidebar-item>
                    <!-- <sidebar-item :link="{name: 'Work Report', path: '/report/work-report'}"></sidebar-item> -->
                    <sidebar-item :link="{ name: 'Export', path: '/report/export' }"
                        v-if="authUserPermission['export-view']"></sidebar-item>
                    <!-- <sidebar-item :link="{name: 'Import', path: '/report/import'}"></sidebar-item> -->
                    <sidebar-item :link="{ name: 'SAP Import', path: '/report/sap-import' }"></sidebar-item>
                    <sidebar-item :link="{ name: 'App Auditing' }"
                        v-if="authUserPermission['model-log-view'] || authUserPermission['transaction-log-view'] || authUserPermission['activity-log-view']">
                        <sidebar-item :link="{ name: 'Model Log', path: '/report/model-log' }"
                            v-if="authUserPermission['model-log-view']"></sidebar-item>
                        <sidebar-item :link="{ name: 'Transaction Log', path: '/report/transaction-log' }"
                            v-if="authUserPermission['transaction-log-view']"></sidebar-item>
                        <sidebar-item :link="{ name: 'Activity Log', path: '/report/activity-log' }"
                            v-if="authUserPermission['activity-log-view']"></sidebar-item>
                    </sidebar-item>
                    <sidebar-item :link="{ name: 'KPI Report' }"
                        v-if="authUserPermission['kpi-mcr-approval-view'] || authUserPermission['kpi-mcr-creation-view'] || authUserPermission['mcr-report-view']">
                        <sidebar-item :link="{ name: 'KPI MCR Approval', path: '/report/kpi-mcr-approval' }"
                            v-if="authUserPermission['kpi-mcr-approval-view']"></sidebar-item>
                        <sidebar-item :link="{ name: 'KPI MCR Creation', path: '/report/kpi-mcr-creation' }"
                            v-if="authUserPermission['kpi-mcr-creation-view']"></sidebar-item>
                        <sidebar-item :link="{ name: 'MCR Report', path: '/report/mcr-report' }"
                            v-if="authUserPermission['mcr-report-view']"></sidebar-item>
                    </sidebar-item>
                    <!-- <sidebar-item :link="{name: 'MCR Process', path: '/report/mcr-process'}" v-if="authUserPermission['mcr-process-view']"></sidebar-item> -->
                    <sidebar-item :link="{ name: 'Konsolidasi Report', path: '/report/konsolidasi-report' }"
                        v-if="authUserPermission['konsolidasi-report-view']"></sidebar-item>
                </sidebar-item>

                <!-- MANAGE ACCOUNT -->
                <sidebar-item :link="{ name: 'Manage Account', icon: 'fa fa-users' }"
                    v-if="authUserPermission['user-view'] || authUserPermission['role-view'] || authUserPermission['permission-view']">
                    <sidebar-item :link="{ name: 'Account List', path: '/manage/manage-account' }"
                        v-if="authUserPermission['user-view']"></sidebar-item>
                    <!-- <sidebar-item :link="{name: 'Jabatan', path: '/manage/jabatan'}"></sidebar-item> -->
                    <sidebar-item :link="{ name: 'Manage Role', path: '/manage/manage-role' }"
                        v-if="authUserPermission['role-view']"></sidebar-item>
                    <sidebar-item :link="{ name: 'Manage Permission', path: '/manage/manage-permission' }"
                        v-if="authUserPermission['permission-view']"></sidebar-item>
                    <!-- <sidebar-item :link="{name: 'Department', path: '/manage/department'}"></sidebar-item> -->
                    <!-- <sidebar-item :link="{name: 'Section', path: '/manage/section'}"></sidebar-item> -->
                    <!-- <sidebar-item :link="{name: 'Sub Section', path: '/manage/sub-section'}"></sidebar-item> -->
                </sidebar-item>

                <!-- GENERAL SETTING -->
                <sidebar-item :link="{ name: 'General Setting', icon: 'fa fa-cog' }"
                    v-if="authUserPermission['menu-view'] || authUserPermission['sub-menu-view'] || authUserPermission['mail-configuration-view'] || authUserPermission['database-backup-view'] || authUserPermission['mail-templates-view'] || authUserPermission['tasks-view'] || authUserPermission['task-menus-view'] || authUserPermission['workflows-view'] || authUserPermission['table-column-view'] || authUserPermission['script-view']">
                    <sidebar-item :link="{ name: 'Mail Configuration', path: '/setting/mail-configuration' }"
                        v-if="authUserPermission['mail-configuration-view']"></sidebar-item>
                    <!-- <sidebar-item :link="{name: 'Notification', path: '/setting/notification'}"></sidebar-item> -->
                    <!-- <sidebar-item :link="{name: 'MCR Status', path: '/setting/mcr-status'}"></sidebar-item> -->
                    <!-- <sidebar-item :link="{name: 'Database Backup', path: '/setting/database-backup'}" v-if="authUserPermission['database-backup-view']"></sidebar-item> -->
                    <sidebar-item :link="{ name: 'Settings', path: '/setting/settings' }"></sidebar-item>
                    <sidebar-item :link="{ name: 'Manage Menu' }">
                        <sidebar-item :link="{ name: 'Menu', path: '/setting/manage-menu' }"
                            v-if="authUserPermission['menu-view']"></sidebar-item>
                        <sidebar-item :link="{ name: 'Sub Menu', path: '/setting/manage-sub-menu' }"
                            v-if="authUserPermission['sub-menu-view']"></sidebar-item>
                    </sidebar-item>
                    <sidebar-item :link="{ name: 'Workflow Approval' }"
                        v-if="authUserPermission['workflows-view'] || authUserPermission['table-column-view'] || authUserPermission['script-view'] || authUserPermission['form-view'] || authUserPermission['tasks-view'] || authUserPermission['task-menus-view'] || authUserPermission['mail-templates-view']">
                        <sidebar-item :link="{ name: 'Workflows', path: '/setting/workflows' }"
                            v-if="authUserPermission['workflows-view']"></sidebar-item>
                        <sidebar-item :link="{ name: 'Mail Templates', path: '/setting/mail-templates' }"
                            v-if="authUserPermission['mail-templates-view']"></sidebar-item>
                        <sidebar-item :link="{ name: 'Script', path: '/setting/script' }"
                            v-if="authUserPermission['script-view']"></sidebar-item>
                        <sidebar-item :link="{ name: 'Table Column', path: '/setting/table-column' }"
                            v-if="authUserPermission['table-column-view']"></sidebar-item>
                        <sidebar-item :link="{ name: 'Form', path: '/setting/form' }"
                            v-if="authUserPermission['form-view']"></sidebar-item>
                        <sidebar-item :link="{ name: 'Tasks', path: '/setting/tasks' }"
                            v-if="authUserPermission['tasks-view']"></sidebar-item>
                        <sidebar-item :link="{ name: 'Task Menus', path: '/setting/task-menus' }"
                            v-if="authUserPermission['task-menus-view']"></sidebar-item>
                    </sidebar-item>
                    <sidebar-item :link="{ name: 'SAP' }">
                        <sidebar-item :link="{ name: 'SAP Connection', path: '/setting/sap-connection' }"
                            v-if="authUserPermission['sap-connection-view']"></sidebar-item>
                        <sidebar-item :link="{ name: 'SAP Config', path: '/setting/config-sap-sync' }"></sidebar-item>
                    </sidebar-item>
                </sidebar-item>

                <!-- FAQ -->
                <sidebar-item :link="{ name: 'FAQ', icon: 'fa fa-question-circle', path: '/setting/faq' }"
                    v-if="authUserPermission['faq-view']">
                    <sidebar-item :link="{ name: 'FAQ', path: '/setting/faq' }"
                        v-if="authUserPermission['faq-view']"></sidebar-item>
                    <a class="nav-link"
                        href="https://drive.google.com/drive/folders/1FRAw-7v-D3r5_bQlxcNDA3Mk2idtMtrh?usp=sharing"
                        target="_blank">
                        User Guide
                    </a>
                </sidebar-item>
            </template>
        </side-bar>
        <div class="main-content">
            <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
            <div @click="$sidebar.displaySidebar(false)">
                <fade-transition :duration="200" origin="center top" mode="out-in">
                    <router-view></router-view>
                </fade-transition>
            </div>
            <!-- <content-footer v-if="!$route.meta.hideFooter"></content-footer> -->
        </div>
    </div>
</template>
<script>
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
    if (hasElement(className)) {
        new PerfectScrollbar(`.${className}`);
    } else {
        setTimeout(() => {
            initScrollbar(className);
        }, 100);
    }
}

import DashboardNavbar from './DashboardNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import { FadeTransition } from 'vue2-transitions';
import { mapState, mapActions } from 'vuex'
import Api from '@/helpers/api';
import authService from '@/services/auth.service';

export default {
    components: {
        DashboardNavbar,
        ContentFooter,
        FadeTransition
    },
    methods: {
        ...mapActions({
            authToken: 'auth/setToken',
            authAttemp: 'auth/attemp'
        }),
        initScrollbar() {
            let isWindows = navigator.platform.startsWith('Win');
            if (isWindows) {
                initScrollbar('sidenav');
            }
        }
    },
    computed: {
        ...mapState({
            authUserPermission: state => state.auth.userPermission
        }),
    },
    mounted() {
        this.initScrollbar()
        let context = this;
        Api(context, authService.userProfile()).onSuccess(function (response) {
            context.authAttemp(response.data.data.user);
        }).call();

        // // CHECK INTERNET CONNECTION
        // window.addEventListener('online', () => {
        //     this.$notify({
        //         message: "You're Online",                  
        //         type: 'success',
        //         verticalAlign: "buttom",
        //         horizontalAlign: "left"
        //     });
        // });
        // window.addEventListener('offline', () => {
        //     this.$notify({
        //         message: "You're Offline",                  
        //         type: 'danger',
        //         verticalAlign: "buttom",
        //         horizontalAlign: "left",
        //         timeout: 0
        //     });
        // });
    }
};
</script>
